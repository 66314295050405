<template>
  <div>
    <v-container fluid class="px-6 mt-10">
      <v-row>
        <v-col class="mx-auto pt-0" cols="10">
          <v-card class="card-shadow border-radius-xl mb-30">
            <div class="card-header-padding">
              <p class="font-weight-bold text-h6 text-typo mb-0">
                How to work on project
              </p>
            </div>
            <v-card-text class="card-padding pt-0 font-size-root text-body">
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title class="text-body"
                    >To start using this product you will need to meet with the following:</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Dashboard",
};
</script>
